import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, Card, Form, Button, Alert } from 'react-bootstrap'
import * as actions from '../store/actions/auth'

export default function Signup() {
    const [userName, setUserName] = useState('')
    const [userPwd1, setUserPwd1] = useState('')
    const [userPwd2, setUserPwd2] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [key, setKey] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const error = useSelector((state) => state.error)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cardBackgroundColor = '#d9e0ea';  // In questo caso grigio chiaro
    const activeOuterBorderColor = '#18303e';  // Blu scuro
    const activeInnerBorderColor = '#c95e15';  // Arancione

    useEffect(() => {
        document.body.classList.add('signup-page');
        return () => {
            document.body.classList.remove('signup-page');
        };
    }, []);

    const validatePassword = (password) => {
        if (password.length < 8) {
            return "Password must have at least 8 characters.";
        }
        if (!/[A-Z]/.test(password)) {
            return "The password must contain at least one uppercase character.";
        }
        if (!/[a-z]/.test(password)) {
            return "The password must contain at least one lowercase character.";
        }
        if (!/[0-9]/.test(password)) {
            return "The password must contain at least one number.";
        }
        if (!/[!@#$%^&_*]/.test(password)) {
            return "The password must contain at least one special character. (!@#$%^&_*).";
        }
        return "";
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setUserPwd1(newPassword);
        setPasswordError(validatePassword(newPassword));
    };

    const signup = (e) => {
        e.preventDefault();
        if (userName && userPwd1 && userPwd2 && userEmail && key) {
            if (userPwd1 !== userPwd2) {
                setPasswordError("Le password non coincidono.");
                return;
            }
            const error = validatePassword(userPwd1);
            if (error) {
                setPasswordError(error);
                return;
            }
            console.log("Chiave inviata a dispatch:", key);
            console.log("Chiave di attivazione inviata:", key);
            dispatch(actions.authSignup(userName, userEmail, userPwd1, userPwd2, key, navigate))
                .then(() => {
                    alert("Registrazione avvenuta con successo! Ora puoi effettuare il login.");
                })
                .catch(error => {
                    setPasswordError(error.message || "Errore durante la registrazione");
                });
        } else {
            alert('Tutti i campi sono obbligatori, inclusa la chiave di attivazione');
        }
    }

    return (
        <Container className='w-50 mt-5'>
            <Card className=''
                style={{
                    border: `2.8px solid ${activeOuterBorderColor}`,
                    boxShadow: `inset 0 0 0 1.8px ${activeInnerBorderColor}`,
                    backgroundColor: cardBackgroundColor,
                }}>
                <Card.Header>
                    <h2 className='mb-3'>Registrazione PumaTools</h2>
                </Card.Header>
                <Card.Body>
                    <Form className='' onSubmit={(e) => signup(e)}>
                        <Form.Group className="mb-3" controlId="formBasicUsername">
                            <Form.Label style={{ fontWeight: 'bold' }}>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Insert username"
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ fontWeight: 'bold' }}>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Insert e-mail"
                                onChange={(e) => setUserEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword1">
                            <Form.Label style={{ fontWeight: 'bold' }}>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={handlePasswordChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword2">
                            <Form.Label style={{ fontWeight: 'bold' }}>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Conferma Password"
                                onChange={(e) => setUserPwd2(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicActivationKey">
                            <Form.Label style={{ fontWeight: 'bold' }}>Activation key</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Insert activation key"
                                onChange={(e) => setKey(e.target.value)}
                            />
                        </Form.Group>
                        {passwordError && <Alert variant="warning">{passwordError}</Alert>}
                        {error && <Alert variant="danger">{error.response?.data?.ErrorMessage || JSON.stringify(error)}</Alert>}
                        <Button variant="primary" type='submit' className='mb-3'>
                            Register
                        </Button>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Button variant="link" type="button" onClick={() => navigate('/login')}>
                        Torna al login
                    </Button>
                </Card.Footer>
            </Card>
        </Container>
    )
}
import React, { useState, useRef, useEffect } from 'react';
import { useDb } from '../contexts/DbContext';
import { variables } from '../Variables';
import { Table, Container, Button, Alert, ProgressBar, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Cookies from 'js-cookie';
import UtilitiesNavTabs from './UtilitiesNavTabs';

console.log("Utilities component loaded");

// Stile in linea per il testo più piccolo nella tabella
const smallTextStyle = {
  fontSize: '0.7rem'
};

export function Utilities({ updateDatabases }) {
  console.log("Utilities function rendered");

  // Utilizzo del context per gestire i database
  const { dbs, refreshDbs } = useDb();

  // Stati per gestire l'interfaccia utente e il processo di upload
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [initProgress, setInitProgress] = useState(0);
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Riferimenti per l'input file e l'intervallo di aggiornamento del progresso
  const dbUpload = useRef(null);
  const progressInterval = useRef(null);

  // Effetto eseguito al montaggio del componente
  useEffect(() => {
    console.log("Utilities component mounted");
    console.log("API_URL:", variables.API_URL);

    // Pulizia dell'intervallo di progresso quando il componente viene smontato
    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, []);

  // Funzione per ottenere gli headers per le richieste API
  const getHeaders = () => {
    return {
      'X-CSRF-TOKEN': Cookies.get('csrf_token'),
      'Authorization': `Bearer ${Cookies.get('token')}`,
    };
  };

  // Funzione per gestire il caricamento del file
  const handleFileUpload = async (event) => {
    console.log("handleFileUpload called");
    const file = event.target.files?.[0];
    if (!file) {
      console.error('Nessun file selezionato');
      return;
    }

    console.log("File selezionato:", file.name);

    const formData = new FormData();
    formData.append("file", file, file.name);

    setIsUploading(true);
    setUploadProgress(0);
    setInitProgress(0);
    setError(null);

    try {
      const headers = getHeaders();
      delete headers['Content-Type']; // Rimuoviamo Content-Type per permettere al browser di impostarlo correttamente per FormData

      console.log("Inizio upload del file:", file.name);
      console.log("URL di caricamento:", variables.API_URL + 'loaddb');
      console.log("Headers:", headers);

      const response = await fetch(variables.API_URL + 'loaddb', {
        method: 'POST',
        headers: headers,
        body: formData,
        credentials: 'include',
      });

      console.log("Risposta ricevuta:", response.status);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log("Caricamento completato:", data);
      setIsUploading(false);
      refreshDbs();
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Errore nel caricamento del file:", error);
      setIsUploading(false);
      setError(`Errore nel caricamento del DB: ${error.message}`);
    }

    // Avvia l'aggiornamento della barra di progresso
    progressInterval.current = setInterval(updateProgressBar, 1000);
  };

  // Funzione per aggiornare la barra di progresso
  const updateProgressBar = async () => {
    try {
      const response = await fetch(variables.API_URL + 'upload-progress', {
        headers: getHeaders(),
        credentials: 'include',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Progresso ricevuto:", data);
      setUploadProgress(Math.round(data.upload_progress));
      setInitProgress(Math.round(data.init_progress));

      if (data.completed) {
        console.log("Upload completato");
        clearInterval(progressInterval.current);
        setIsUploading(false);
        setShowSuccessModal(true);
        refreshDbs();
      }
    } catch (error) {
      console.error('Errore nel recupero del progresso:', error);
      clearInterval(progressInterval.current);
      setIsUploading(false);
      setError(`Errore nel monitoraggio del progresso: ${error.message}`);
    }
  };

  // Funzione per testare la connessione con il server
  const testConnection = async () => {
    console.log("Inizio test di connessione");
    const testUrl = `${variables.API_URL}test-endpoint/`;
    console.log("URL del test:", testUrl);
    
    try {
      const response = await fetch(testUrl, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log("Risposta ricevuta:", response);
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Dati ricevuti dal test:", data);
      alert("Connessione al server riuscita!");
    } catch (error) {
      console.error("Errore dettagliato nel test di connessione:", error);
      console.log("Tipo di errore:", error.name);
      console.log("Messaggio di errore:", error.message);
      console.log("Stack trace:", error.stack);
      alert(`Errore nella connessione al server: ${error.message}`);
    }
  };

  // Funzione per eliminare un database
  const deleteClick = async (id) => {
    try {
      const response = await fetch(variables.API_URL + 'db/' + id, {
        method: 'DELETE',
        headers: getHeaders(),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.text();
      if (result === "Deleted successfully") {
        alert("Database deleted successfully");
        refreshDbs();
      } else {
        throw new Error(result || "Unknown error occurred");
      }
    } catch (error) {
      console.error('Error deleting database:', error);
      alert('Failed to delete db ' + id + ': ' + error.message);
    }
  };

  // Funzione per chiudere la modale di successo
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  // Funzione per simulare il click sull'input file
  const handleUploadClick = () => {
    dbUpload.current?.click();
  };

  // Rendering del componente
  return (
    <Container fluid className="p-4">
      {/* Componente di navigazione a tab condiviso */}
      <UtilitiesNavTabs />
      
      {/* Barre di progresso per l'upload e l'inizializzazione */}
      {isUploading && (
        <div>
          <ProgressBar now={uploadProgress} label={`Caricamento ${uploadProgress}%`} />
          <ProgressBar now={initProgress} label={`Inizializzazione ${initProgress}%`} />
        </div>
      )}

      {/* Visualizzazione degli errori */}
      {error && <Alert variant="danger">Errore: {error}</Alert>}

      {/* Tabella dei database o messaggio se non ce ne sono */}
      {dbs.length === 0 ? (
        <Alert variant="info">Nessun database presente.</Alert>
      ) : (
        <Table striped>
          <thead>
            <tr>
              <th>Id</th>
              <th>Db Name</th>
              <th>Db Path</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dbs.map((db) => (
              <tr key={db.id} style={smallTextStyle}>
                <td>{db.id}</td>
                <td>{db.name}</td>
                <td>{db.db_path}</td>
                <td>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => deleteClick(db.id)}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Pulsanti per caricare un nuovo DB e testare la connessione */}
      <div className="mt-3">
        <input
          ref={dbUpload}
          className="d-none"
          type="file"
          onChange={handleFileUpload}
        />
        <Button variant="primary" onClick={handleUploadClick} disabled={isUploading} className="me-2">
          {isUploading ? 'Caricamento in corso...' : 'Carica nuovo DB'}
        </Button>
        <Button variant="secondary" onClick={testConnection}>
          Test Connessione
        </Button>
      </div>

      {/* Modale di successo */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Caricamento completato</Modal.Title>
        </Modal.Header>
        <Modal.Body>Upload del DB terminato correttamente</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Utilities;
import React from 'react';
import { useNavigate } from 'react-router-dom';

const UtilitiesNavTabs = ({ activeTab }) => {
  const navigate = useNavigate();

  return (
    <div className="mb-4">
      <nav className="nav nav-tabs">
        <button 
          className={`nav-link ${activeTab === 'utilities' ? 'active' : ''}`}
          onClick={() => navigate('/utilities')}
          style={{
            backgroundColor: activeTab === 'utilities' ? '#18303e' : '#eee',
            color: activeTab === 'utilities' ? 'white' : '#6c757d',
            fontWeight: activeTab === 'utilities' ? 'bold' : 'normal',
            borderBottom: activeTab === 'utilities' ? 'none' : '1px solid #dee2e6',
            borderColor: activeTab === 'utilities' ? '#18303e' : '#dee2e6',
            borderStyle: 'solid',
            borderWidth: '1px',
            marginBottom: '-1px',
            zIndex: activeTab === 'utilities' ? '1' : 'auto'
          }}
        >
          Import DB Puma
        </button>
        <button 
          className={`nav-link ${activeTab === 'finbil-rules' ? 'active' : ''}`}
          onClick={() => navigate('/utilities-finbil-rules')}
          style={{
            backgroundColor: activeTab === 'utilities' ? '#18303e' : '#eee',
            color: activeTab === 'utilities' ? 'white' : '#6c757d',
            fontWeight: activeTab === 'utilities' ? 'bold' : 'normal',
            borderBottom: activeTab === 'utilities' ? 'none' : '1px solid #dee2e6',
            borderColor: activeTab === 'utilities' ? '#18303e' : '#dee2e6',
            borderStyle: 'solid',
            borderWidth: '1px',
            marginBottom: '-1px',
            zIndex: activeTab === 'utilities' ? '1' : 'auto'
          }}
        >
          Import Finbil Rules
        </button>
      </nav>
    </div>
  );
};

export default UtilitiesNavTabs;
import React, { useState } from 'react';
import { Container, Button, Form, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { Export } from './Export';
import { connect } from 'react-redux';
import { DTable } from './DTable';

// Funzione di utilità per ottenere l'URL base
const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:8000';
  }
  return window.location.origin;
};

// Funzione per convertire data da YYYY-MM-DD a YYYYMMDD
const formatDateForFilename = (dateString) => {
  return dateString.replace(/-/g, '');
};

/* Variabili di stile per facile personalizzazione */
const styleVariables = {
  // Colori di base
  labelBackground: '#D1D8E8',
  labelBorderOuter: '#18303e',
  labelBorderInner: '#c95e15',
  labelText: '#000000',
  
  // Colori dei pulsanti Browse
  browseButtonBackground: 'transparent',
  browseButtonBorder: '#18303E',
  browseButtonText: '#18303E',
  
  // Dimensioni comuni
  commonWidth: '180px',
  commonHeight: '30px',
  fontSize: '0.70rem',
  labelFontSize: '0.75rem',
  buttonFontSize: '0.70rem'
};

/* Stili delle etichette */
const labelStyles = {
  backgroundColor: styleVariables.labelBackground,
  border: `2.3px solid ${styleVariables.labelBorderOuter}`,
  boxShadow: `inset 0 0 0 1px ${styleVariables.labelBorderInner}`,
  height: styleVariables.commonHeight,
  width: styleVariables.commonWidth,
  color: styleVariables.labelText,
  fontSize: styleVariables.labelFontSize
};

/* Stili dei pulsanti Browse */
const browseButtonStyles = {
  width: styleVariables.commonWidth,
  height: styleVariables.commonHeight,
  backgroundColor: styleVariables.browseButtonBackground,
  color: styleVariables.browseButtonText,
  borderColor: styleVariables.browseButtonBorder,
  fontSize: styleVariables.buttonFontSize
};

// Definizione dei pattern per i nomi dei file
const filePatterns = {
  'CENTRALE RISCHI': {
    prefix: 'CR_',
    getExpectedName: (abi, date) => `CR_${abi}_${formatDateForFilename(date)}`
  },
  'CREDITI DI FIRMA': {
    prefix: ['CFPUMA_', 'CASSA_FIRMA_'],
    getExpectedName: (abi, date) => [
      `CFPUMA_${abi}_${formatDateForFilename(date)}`,
      `CASSA_FIRMA_${abi}_${formatDateForFilename(date)}`
    ]
  }
};

// Definizione dei tipi di file accettati
const acceptedFileTypes = {
  'CENTRALE RISCHI': '.pdf',
  'CREDITI DI FIRMA': '.zip'
};

const CrCheck = (props) => {
  /* Stati del componente */
  const [files, setFiles] = useState({
    'CENTRALE RISCHI': null,
    'CREDITI DI FIRMA': null
  });
  const [resultData, setResultData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [headerData, setHeaderData] = useState(null);

  /* Gestione del caricamento dei file */
  const handleFileChange = (event, key) => {
    const file = event.target.files[0];
    if (!file) return;

    // Verifica estensione del file
    const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
    if (!acceptedFileTypes[key].includes(fileExtension)) {
      alert(`Per ${key} sono accettati solo i seguenti formati: ${acceptedFileTypes[key]}`);
      return;
    }

    // Ottieni il nome del file senza estensione
    const fileName = file.name.split('.')[0];
    const pattern = filePatterns[key];
    
    // Verifica che il file inizi con il prefisso corretto
    if (key === 'CREDITI DI FIRMA') {
      const validPrefix = pattern.prefix.some(prefix => fileName.startsWith(prefix));
      if (!validPrefix) {
        alert(`Il nome del file deve iniziare con uno dei seguenti prefissi: ${pattern.prefix.join(' o ')}`);
        return;
      }
    } else {
      if (!fileName.startsWith(pattern.prefix)) {
        alert(`Il nome del file deve iniziare con "${pattern.prefix}"`);
        return;
      }
    }

    // Se tutte le validazioni passano, aggiorna lo stato
    setFiles(prevFiles => ({ ...prevFiles, [key]: file }));
  };

  /* Gestione dell'invio dei dati al backend */
  const handleStartQuery = async () => {
    // Verifica token
    if (!props.token) {
      console.error("Token non disponibile");
      setError("Sessione scaduta. Effettua nuovamente il login.");
      return;
    }

    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    
    // Aggiungi i file al FormData
    Object.entries(files).forEach(([key, file]) => {
      if (file) {
        formData.append(key.toLowerCase().replace(/ /g, '_'), file);
      }
    });

    try {
      // Submit job con token di autorizzazione
      const submitResponse = await fetch('/api/cr-pdfelab/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${props.token}`
        },
        credentials: 'include',
        body: formData,
      });

      if (!submitResponse.ok) {
        throw new Error(`HTTP error! status: ${submitResponse.status}`);
      }

      const { job_id } = await submitResponse.json();

      // Poll per lo stato
      const pollStatus = async () => {
        try {
          const statusResponse = await fetch(`/api/cr-pdfelab-status/${job_id}/`, {
            headers: {
              'Authorization': `Token ${props.token}`
            },
            credentials: 'include'
          });

          const status = await statusResponse.json();

          if (status.status === 'completed') {
            if (status.result) {
              setHeaderData(status.result.header_data);
              setResultData(status.result.data);

              // Gestione download automatico
              if (status.result.download_token) {
                const baseUrl = getBaseUrl();
                const downloadUrl = `${baseUrl}/api/download-cr/${status.result.download_token}/`;
                
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = `cr_result_${new Date().toISOString()}.zip`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
            setIsLoading(false);
          } else if (status.status === 'error') {
            setError(status.error);
            setIsLoading(false);
          } else {
            setTimeout(pollStatus, 2000);
          }
        } catch (error) {
          setError('Error checking status: ' + error.message);
          setIsLoading(false);
        }
      };

      pollStatus();

    } catch (error) {
      setError('Error processing query: ' + error.message);
      setIsLoading(false);
    }
  };

  /* Componente per la riga di input file */
  const renderFileInput = (key) => (
    <div className="d-flex align-items-center mb-3">
      {/* Etichetta del file */}
      <div 
        className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
        style={labelStyles}
      >
        <Form.Label className="fw-bold mb-0">
          {key}
        </Form.Label>
      </div>

      {/* Input file nascosto e bottone Browse */}
      <div className="d-flex align-items-center">
        <input
          type="file"
          accept={acceptedFileTypes[key]}
          onChange={(e) => handleFileChange(e, key)}
          style={{ display: 'none' }}
          id={`file-${key}`}
        />
        <Button 
          as="label" 
          htmlFor={`file-${key}`}
          variant="outline-primary"
          className="me-3"
          style={browseButtonStyles}
        >
          Browse {key}
        </Button>
        <span className="text-muted" style={{ fontSize: styleVariables.fontSize }}>
          {files[key] ? files[key].name : 'No file selected'}
        </span>
      </div>
    </div>
  );

  // Componente per renderizzare la tabella della testata
  const renderHeaderTable = () => (
    headerData && (
        <Row className="mb-4">
            <Col>
                <table className="table table-bordered table-sm">
                    <tbody>
                        {Object.entries(headerData).map(([key, value]) => (
                            <tr key={key}>
                                <td style={{ backgroundColor: styleVariables.labelBackground, width: '200px' }}>
                                    <strong>{key}</strong>
                                </td>
                                <td>{value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Col>
        </Row>
    )
  );

  return (
    <Container fluid className="p-4">
      {/* Barra delle azioni in alto */}
      <Row className="mb-4">
        <Col className="d-flex justify-content-end gap-3">
          <Button 
            onClick={handleStartQuery} 
            variant="success"
            disabled={isLoading}
            style={{ width: '150px' }}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Start Job'}
          </Button>
          <Export 
            filename='CrCheck_Result'
            data={resultData || []}
            disabled={!resultData}
            style={{ width: '150px' }}
          />
        </Col>
      </Row>

      {/* Form principale */}
      <Row>
        <Col>
          {renderFileInput('CENTRALE RISCHI')}
          {renderFileInput('CREDITI DI FIRMA')}
        </Col>
      </Row>

      {headerData && renderHeaderTable()}
      {/* Messaggi di errore e risultati */}
      {error && (
        <Row className="mt-4">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      {resultData && (
        <Row className="mt-4">
          <Col>
            <DTable
              data={resultData}
              filtered={['codice_regola', 'misura_output', 'riga', 'colonna', 'valore']}
              size="sm"
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state.token
});

export default connect(mapStateToProps)(CrCheck);
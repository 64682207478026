import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';  // Aggiungiamo connect da react-redux

const UserPermissionsEditor = ({ user, show, onHide, onUpdate, token }) => {  // Aggiungiamo token alle props
    const [permissions, setPermissions] = useState({
        is_superuser: user.is_superuser,
        is_finrisk: user.is_finrisk,
        is_finbil: user.is_finbil,
        is_crcheck: user.is_crcheck,
        is_sdvcheck: user.is_sdvcheck
    });
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setPermissions(prev => ({
            ...prev,
            [name]: checked
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        try {
            if (!token) {
                throw new Error('Token di autenticazione non trovato');
            }

            const response = await fetch(`/api/users/${user.id}/permissions/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,  // Usiamo il token dalle props
                },
                credentials: 'include',
                body: JSON.stringify(permissions)
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Errore durante l\'aggiornamento dei permessi');
            }

            const data = await response.json();
            onUpdate(data.user);
            onHide();
        } catch (err) {
            setError(err.message);
            console.error('Errore durante l\'aggiornamento:', err);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Modifica Permessi: {user.username}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <div className="alert alert-danger">{error}</div>
                )}
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            id="is_superuser"
                            name="is_superuser"
                            label="Superuser"
                            checked={permissions.is_superuser}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            id="is_finrisk"
                            name="is_finrisk"
                            label="Finrisk"
                            checked={permissions.is_finrisk}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            id="is_finbil"
                            name="is_finbil"
                            label="Finbil"
                            checked={permissions.is_finbil}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            id="is_crcheck"
                            name="is_crcheck"
                            label="CR Check"
                            checked={permissions.is_crcheck}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            id="is_sdvcheck"
                            name="is_sdvcheck"
                            label="SDV Check"
                            checked={permissions.is_sdvcheck}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Annulla
                </Button>
                <Button 
                    variant="primary" 
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Salvataggio...' : 'Salva Modifiche'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

// Mappiamo lo state Redux alle props
const mapStateToProps = (state) => ({
    token: state.token
});

// Esportiamo il componente connesso a Redux
export default connect(mapStateToProps)(UserPermissionsEditor);
import React, { useState, useEffect, useRef } from 'react';
import { Container, Dropdown, Button, Table, Alert, Row, Col, Spinner, ProgressBar as BootstrapProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { DTableSurveyComp } from './DTableSurveyComp';
import * as XLSX from 'xlsx';
import { Export } from './Export';
import './CompareSurvey.scss';

const CompareSurvey = () => {
  // Stati per la gestione dei dati del form
  const [surveyTypes, setSurveyTypes] = useState([]); // Lista dei tipi di survey disponibili
  const [selectedSurveyType, setSelectedSurveyType] = useState(null); // Tipo di survey selezionato
  const [survey1, setSurvey1] = useState(null); // Primo file caricato
  const [survey2, setSurvey2] = useState(null); // Secondo file caricato
  
  // Stati per la gestione dei risultati e dello stato dell'applicazione
  const [comparisonResult, setComparisonResult] = useState(null); // Risultati del confronto
  const [error, setError] = useState(null); // Gestione errori
  const [isLoading, setIsLoading] = useState(false); // Stato di caricamento generale
  const [isPolling, setIsPolling] = useState(false); // Stato specifico per il polling
  const [progress, setProgress] = useState(0); // Progresso dell'elaborazione
  const [dataReceived, setDataReceived] = useState(false); // Flag per dati ricevuti
  const [testata, setTestata] = useState(null); // Dati di testata
  const [surveyId, setSurveyId] = useState(null); // ID univoco della survey
  const [isXbrl, setIsXbrl] = useState(false); // Flag per tipo XBRL
  const [isVisible, setIsVisible] = useState(true); // Stato per animazioni fade

  // Ref per gestire l'intervallo di polling
  const pollIntervalRef = useRef(null);

  // Stili per i bottoni
  const buttonStyle = { width: '160px', maxWidth: '100%' };
  const surveyTypeButtonStyle = { ...buttonStyle, width: '200px' };

  // Funzione migliorata per il polling del progresso
  const pollProgress = async (id) => {
    console.log('Starting progress polling for survey ID:', id);
    
    // Ferma eventuali polling precedenti
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }
  
    const interval = setInterval(async () => {
      try {
        const response = await axios.get(`/api/progress/${id}/`);
        
        if (response.status === 404 || response.data.status === 'completed') {
          console.log('Processing completed, stopping polling');
          clearInterval(interval);
          pollIntervalRef.current = null;
          setIsPolling(false);
          setIsLoading(false);
          return;
        }
  
        const progressValue = response.data.progress || response.data;
        if (progressValue !== undefined) {
          setProgress(prev => Math.max(prev, progressValue));
  
          if (progressValue >= 100) {
            console.log('Progress complete, stopping polling');
            clearInterval(interval);
            pollIntervalRef.current = null;
            setIsPolling(false);
            setIsLoading(false);
          }
        }
      } catch (error) {
        if (error.response?.status === 404) {
          console.log('Processing completed (404), stopping polling');
          clearInterval(interval);
          pollIntervalRef.current = null;
          setIsPolling(false);
          setIsLoading(false);
        } else {
          console.error('Error in progress polling:', error);
        }
      }
    }, 3000);
  
    pollIntervalRef.current = interval;
  };

  // Effetto per il caricamento iniziale dei tipi di survey
  useEffect(() => {
    axios.get('/api/survey-types/')
      .then(response => {
        setSurveyTypes(response.data);
      })
      .catch(error => {
        console.error('Errore nel recupero dei tipi di survey:', error);
        setError('Failed to fetch survey types');
      });
  }, []);

  // Effetto per la gestione del polling quando cambia il surveyId
  useEffect(() => {
    if (surveyId) {
      setIsPolling(true);
      pollProgress(surveyId);
    }
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
        pollIntervalRef.current = null;
        setIsPolling(false);
      }
    };
  }, [surveyId]);

  // Cleanup quando il componente viene smontato
  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) {
        console.log('Component unmounting, cleaning up polling');
        clearInterval(pollIntervalRef.current);
        pollIntervalRef.current = null;
        setIsPolling(false);
        setIsLoading(false);
      }
    };
  }, []);

  // Funzione per la selezione del tipo di survey
  const handleSurveyTypeSelect = (surveyType) => {
    setSelectedSurveyType(surveyType);
    setIsXbrl(surveyType.survey_type.toLowerCase() === 'xbrl');
  };

  // Funzione per la gestione del caricamento dei file
  const handleFileUpload = (event, surveyNumber) => {
    const file = event.target.files[0];
    if (file) {
      console.log(`File ${surveyNumber} selected:`, file.name);
      
      // Verifica che sia stato selezionato un tipo di survey
      if (!selectedSurveyType) {
        setError('Please select a survey type before uploading a file');
        return;
      }

      // Verifica dell'estensione del file
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['txt', 'xml', 'xbrl'];

      if (!allowedExtensions.includes(fileExtension)) {
        setError(`Invalid file type. Allowed extensions are: ${allowedExtensions.join(', ')}`);
        return;
      }

      // Verifica che l'estensione corrisponda al tipo di survey selezionato
      if (fileExtension !== selectedSurveyType.survey_type.toLowerCase()) {
        setError(`Invalid file type. Expected .${selectedSurveyType.survey_type.toLowerCase()} for the selected survey type`);
        return;
      }

      // Imposta il file nel suo stato corrispondente
      if (surveyNumber === 1) {
        setSurvey1(file);
      } else {
        setSurvey2(file);
      }

      // Pulisci eventuali errori precedenti
      setError(null);
    }
  };

  // Funzione principale per l'avvio della query di confronto
  const handleStartQuery = async () => {
    console.log('handleStartQuery called, isXbrl:', isXbrl);
    
    // Gestione della transizione di visibilità
    setIsVisible(false);
    await new Promise(resolve => setTimeout(resolve, 300));

    // Reset degli stati precedenti
    setComparisonResult(null);
    setTestata(null);
    setDataReceived(false);
    setError(null);
    setProgress(0);

    // Validazione dei dati richiesti
    if (!selectedSurveyType || !survey1 || !survey2) {
      console.log('Dati mancanti:', { selectedSurveyType, survey1, survey2 });
      setError('Please select a survey type and upload both surveys');
      setIsVisible(true);
      return;
    }

    // Impostazione degli stati di caricamento
    setIsLoading(true);
    setIsPolling(true);  // Aggiungiamo esplicitamente l'impostazione del polling

    // Generazione dell'ID univoco per la survey
    const clientGeneratedSurveyId = `client-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    setSurveyId(clientGeneratedSurveyId);

    // Preparazione dei dati da inviare
    const formData = new FormData();
    formData.append('survey_type', selectedSurveyType.survey_code);
    formData.append('survey1', survey1);
    formData.append('survey2', survey2);
    formData.append('client_survey_id', clientGeneratedSurveyId);

    try {
      // Invio della richiesta al backend
      console.log('Invio richiesta per comparazione delle surveys');
      const response = await axios.post('/api/compare-surveys/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // Parsing della risposta
      let parsedResponseData = typeof response.data === 'string' 
        ? JSON.parse(response.data) 
        : response.data;

      // Gestione del survey_id dalla risposta
      if (parsedResponseData.survey_id) {
        console.log('Ricevuto survey_id:', parsedResponseData.survey_id);
        setSurveyId(parsedResponseData.survey_id);
      }

      // Gestione della testata
      if (parsedResponseData.testata) {
        console.log('Ricevuta testata:', parsedResponseData.testata);
        setTestata(parsedResponseData.testata);
      }

      // Gestione dei dati di comparazione
      if (parsedResponseData.data && Array.isArray(parsedResponseData.data)) {
        console.log('Ricevuti dati di comparazione, lunghezza:', parsedResponseData.data.length);

        // Pulizia dei dati
        const cleanedData = parsedResponseData.data.map(item => {
          const cleanedItem = {};
          for (const [key, value] of Object.entries(item)) {
            if (typeof value === 'string') {
              cleanedItem[key] = value;
            } else if (typeof value === 'number') {
              cleanedItem[key] = isNaN(value) ? null : value;
            } else {
              cleanedItem[key] = value;
            }
          }
          return cleanedItem;
        });

        setComparisonResult(cleanedData);
        setDataReceived(true);
        setProgress(100);
        
        // Riattiva la visibilità con un piccolo delay
        await new Promise(resolve => setTimeout(resolve, 50));
        setIsVisible(true);
      } else {
        throw new Error('Nessun dato di comparazione nella risposta o formato non valido');
      }

    } catch (error) {
      console.error('Errore in handleStartQuery:', error);
      
      const errorMessage = error.response
        ? 'Errore nella comparazione delle surveys: ' + JSON.stringify(error.response.data)
        : 'Errore nella comparazione delle surveys: ' + error.message;
      
      setError(errorMessage);
      setProgress(0);
      setIsVisible(true);
      
      // Cleanup del polling in caso di errore
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
        pollIntervalRef.current = null;
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Componente per la barra di progresso
  const ProgressBar = () => {
    const displayProgress = dataReceived ? 100 : progress;
    return (isLoading || isPolling) && displayProgress > 0 ? (
      <div className="progress-container">
        <BootstrapProgressBar 
          now={displayProgress} 
          label={`${Math.round(displayProgress)}%`} 
        />
        {isPolling && <small className="text-muted">Elaborazione in corso...</small>}
      </div>
    ) : null;
  };

  return (
    <Container fluid className="mt-4">
      {/* Sezione superiore con controlli */}
      <Row className="mb-4">
        <Col md={6}>
          {/* Dropdown per la selezione del tipo di survey */}
          <div className="mb-3 d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle 
                variant="primary" 
                id="dropdown-survey-type" 
                style={surveyTypeButtonStyle}
              >
                {selectedSurveyType ? selectedSurveyType.survey_description : 'Select Survey Type'}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: '300px' }}>
                {surveyTypes.length > 0 ? (
                  surveyTypes.map(type => (
                    <Dropdown.Item 
                      key={type.Id} 
                      onClick={() => handleSurveyTypeSelect(type)}
                    >
                      {type.survey_description}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No survey types available</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Input per il caricamento del primo file */}
          <div className="mb-3 d-flex align-items-center">
            <input
              type="file"
              onChange={(e) => handleFileUpload(e, 1)}
              style={{ display: 'none' }}
              id="survey1-upload"
            />
            <Button 
              as="label" 
              htmlFor="survey1-upload" 
              variant="outline-primary" 
              style={surveyTypeButtonStyle}
            >
              Browse Survey 1
            </Button>
            <span className="ms-2 text-truncate">
              {survey1 ? survey1.name : 'No file selected'}
            </span>
          </div>

          {/* Input per il caricamento del secondo file */}
          <div className="d-flex align-items-center">
            <input
              type="file"
              onChange={(e) => handleFileUpload(e, 2)}
              style={{ display: 'none' }}
              id="survey2-upload"
            />
            <Button 
              as="label" 
              htmlFor="survey2-upload" 
              variant="outline-primary" 
              style={surveyTypeButtonStyle}
            >
              Browse Survey 2
            </Button>
            <span className="ms-2 text-truncate">
              {survey2 ? survey2.name : 'No file selected'}
            </span>
          </div>
        </Col>

        {/* Colonna dei pulsanti di azione */}
        <Col md={6} className="d-flex flex-column align-items-end">
          {/* Pulsante Start Query */}
          <Button 
            onClick={handleStartQuery} 
            variant="success" 
            disabled={isLoading || isPolling || !selectedSurveyType || !survey1 || !survey2}
            className="mb-2"
            style={{ ...buttonStyle, width: '145px' }}
          >
            {(isLoading || isPolling) ? (
              <>
                <Spinner animation="border" size="sm" className="me-1" />
                Elaborazione...
              </>
            ) : 'Start Query'}
          </Button>

          {/* Pulsante Export */}
          <Export 
            filename='Confronto_base'
            data={comparisonResult || []}
            testata={testata || []}
            disabled={!comparisonResult || comparisonResult.length === 0}
            style={buttonStyle}
          />
        </Col>
      </Row>

      {/* Visualizzazione errori */}
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      {/* Progress Bar */}
      {(isLoading || isPolling) && progress > 0 && (
        <Row className="mb-4">
          <Col>
            <ProgressBar />
          </Col>
        </Row>
      )}

      {/* Tabella Testata */}
      {testata && (
        <Row className="mb-4">
          <Col>
            <Table striped bordered hover size="sm" style={{ fontSize: '0.8rem' }}>
              <thead>
                <tr style={{ fontSize: '0.75rem' }}>
                  <th>File</th>
                  <th>XbrlSchemaRef</th>
                  <th>EntityID</th>
                  <th>Taxonomy</th>
                  <th>FileName</th>
                  <th>Competenza</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: '0.75rem' }}>
                {Array.isArray(testata) ? (
                  testata.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.XbrlSchemaRef}</td>
                      <td>{item.EntityID}</td>
                      <td>{item.Taxonomy}</td>
                      <td>{item.FileName}</td>
                      <td>{item.Competenza}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>1</td>
                    <td>{testata.XbrlSchemaRef}</td>
                    <td>{testata.EntityID}</td>
                    <td>{testata.Taxonomy}</td>
                    <td>{testata.FileName}</td>
                    <td>{testata.Competenza}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {/* Tabella dei risultati con effetto fade */}
      {comparisonResult && comparisonResult.length > 0 && (
        <div className="table-container">
          <div 
            className={`fade-content ${isVisible ? 'fade-enter fade-enter-active' : 'fade-exit fade-exit-active'}`}
            key={comparisonResult.length}
          >
            <DTableSurveyComp 
              columns={Object.keys(comparisonResult[0])}
              data={comparisonResult}
              isXbrl={isXbrl}
              className="survey-table"
            />
          </div>
        </div>
      )}
    </Container>
  );
};

export default CompareSurvey;
import React, { Component } from 'react';
import { variables } from '../Variables';
import { Table, Container, Button, Row, Col } from 'react-bootstrap';
import { LicenseGen } from './LicenseGen';
import * as Icon from 'react-bootstrap-icons'

export class Licenses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ls: [],
            showModal: false
        }
        console.log("Licenses component constructed");
    }

    openModal(e) {
        this.setState({ showModal: true });
    }

    generateLicense = (d) => {
        return new Promise((resolve, reject) => {
            console.log("Generating license:", d);
            fetch('/api/licenses/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(d)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("License generated successfully:", data);
                this.refreshList();
                this.setState({ showModal: false });
                resolve(data);
            })
            .catch(error => {
                console.error("Error generating license:", error);
                reject(error);
            });
        });
    }

    refreshList() {
        fetch('/api/licenses/', { 
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ ls: data })
            })
    }

    componentDidMount() {
        console.log("Licenses component mounted");
        this.refreshList()
    }

    diffInMonths(end) {
        if (end !== undefined) {
            var now = new Date(Date.now());
            var exp = new Date(Date.parse(end));
            var timeDiff = exp.getTime() - now.getTime();
            var diff = Math.round(Math.abs(timeDiff) / (2e3 * 3600 * 365.25))
            if (timeDiff > 0) {
                return 'Expires in ' + diff + ' months'
            }
            else {
                return 'Expired'
            }
        }
        return ''
    }

    deleteClick(id) {
        fetch(`/api/licenses/${id}/`, {
            method: 'DELETE',
            credentials: 'include'
        }).then(res => {
            if (res.status === 204) {
                // Successo - nessun contenuto
                this.refreshList();
                alert('License deleted successfully');
            } else {
                // Prova a leggere il messaggio di errore
                return res.json().then(data => {
                    throw new Error(data.message || 'Failed to delete license');
                });
            }
        })
        .catch(error => {
            alert('Failed to delete license ' + id + ': ' + error.message);
        });
    }

    render() {
        console.log("Licenses component rendering");
        const { ls } = this.state;
        return (
            <div>
                <Container>
                    <Row className="pt-4">
                        <Col className='d-flex justify-content-end'>
                            <Button variant='success' onClick={(e) => this.openModal(e)}>
                                Generate New License
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Table className='mt-3' style={{ width: '1600%', marginLeft: '-4%',marginRight: '-4%' }}>
                            <thead className='sticky-top' style={{ display: 'table', width: '100%', fontSize: '0.6em', tableLayout: 'fixed' }}>
                                <tr style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                    <th style={{ width: '20%' }}>Activation Key</th>
                                    <th style={{ width: '20%' }}>Owner</th>
                                    <th style={{ width: '8%' }}>Is in use</th>
                                    <th style={{ width: '8%' }}>Is Active</th>
                                    <th style={{ width: '11%' }}>Activation Date</th>
                                    <th style={{ width: '11%' }}>Expiration Date</th>
                                    <th style={{ width: '8%' }}>Duration</th>
                                    <th style={{ width: '10%' }}>Info</th>
                                    <th style={{ width: '4%' }}>Action</th>
                                </tr>
                            </thead>

                            <tbody style={{ width: '100%', display: 'block', overflowY: 'scroll', maxHeight: '70vh' }}>
                                {ls.map(l =>
                                    <tr key={l.id} style={{ display: 'table', width: '100%', tableLayout: 'fixed', fontSize: '1em' }}>
                                    <td className='align-middle' style={{ fontSize: '0.6em', width: '20%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{l.activation_key}</td>
                                    <td className='align-middle' style={{ fontSize: '0.6em', width: '20%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{l.owner}</td>
                                    <td className='align-middle' style={{ width: '8%' }}><Icon.Check2 display={l.in_use ? 'inline' : 'none'} color={'green'} /><Icon.X display={l.in_use ? 'none' : 'inline'} color={'red'} /></td>
                                    <td className='align-middle' style={{ width: '8%' }}><Icon.Check2 display={l.active ? 'inline' : 'none'} color={'green'} /><Icon.X display={l.active ? 'none' : 'inline'} color={'red'} /></td>
                                    <td className='align-middle' style={{ fontSize: '0.6em', width: '11%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{l.activation_date}</td>
                                    <td className='align-middle' style={{ fontSize: '0.6em', width: '11%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{l.expiration_date}</td>
                                    <td className='align-middle' style={{ fontSize: '0.6em', width: '8%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{l.duration}</td>
                                    {/* Modificato lo stile della colonna Info */}
                                    <td className='align-middle' style={{ 
                                        fontSize: '0.6em', 
                                        width: '10%',
                                        whiteSpace: 'normal',  // Permette il wrap del testo
                                        wordBreak: 'break-word',  // Permette la rottura delle parole lunghe
                                        padding: '8px',  // Aggiunge un po' di padding per leggibilità
                                        lineHeight: '1.2'  // Migliora la spaziatura tra le righe
                                    }}>
                                        {l.active ? this.diffInMonths(l.expiration_date) : ''}
                                    </td>
                                    <td className='align-middle' style={{ width: '4 %' }}>
                                        <Button variant='outline-danger' size='sm' onClick={() => this.deleteClick(l.id)}><Icon.Trash /></Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        </Table>
                    </Row>
                </Container>

                <LicenseGen show={this.state.showModal} callback={v => this.setState({ showModal: v })} addLicense={this.generateLicense}></LicenseGen>
            </div>
        )
    }
}